@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@import "styles/variables";
@import "styles/mixins";

html {
  height: 100%;
  font-size: 24px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
}

body {
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
  background-attachment: fixed;
}

#loading-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  background-color: #000;
  color: #FFF;
  z-index: 10000;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  transition: opacity ease-in-out 700ms;

  @include text-stroke(2px);
  font-size: 1.2rem;
}

button, input, select {
  border: 2px solid $main;
  color: $main;
  border-radius: 5px;
  background: none;
  backdrop-filter: blur(5px) brightness(90%);

  &:disabled {
    backdrop-filter: blur(5px) brightness(50%);
  }
}

button {
  box-sizing: border-box;
  font-size: 1rem;
  padding: 10px 20px;
}

input {
  font-size: 1rem;
  padding: .4rem;
  outline: none;
}

select {
  font-size: 1rem;
  padding: .4rem;
  outline: none;
  box-sizing: border-box;
}

h1, h2, h3 {
  color: $main;
}

h1 {
  font-size: 1.6rem;
  margin-block: .8rem;
}

h2 {
  font-size: 1.4rem;
  margin-block: .7rem;
}

h3 {
  font-size: 1.3rem;
  margin-block: .6rem;
}

p, li {
  font-size: 1rem;
  margin-block: .6rem;
  color: $main;
}
