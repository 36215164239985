@use "variables" as v;

@mixin text-stroke( $strokeWidth: 1px, $fillClr: v.$main, $strokeClr: v.$accent2) {
  -webkit-text-fill-color: $fillClr;
  -webkit-text-stroke-color: $strokeClr;
  -webkit-text-stroke-width: $strokeWidth
}

@mixin placeholder() {
  opacity: .8;
  animation-name: blink-animation;
  animation-direction: alternate;
  animation-duration: 2s;
  animation-delay: .5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
